<template>
  <form @submit.prevent="handleSubmit">
    <slot name="header"></slot>

    <b-row>
      <b-col lg="6" v-for="docInput in docInputs" :key="docInput.type">
        <b-form-group>
          <label
            ><small>{{ docInput.label }}</small></label
          >
          <b-form-file
            required
            plain
            v-model="docsCopy[docInput.type]"
          ></b-form-file>
          <label v-if="isUploaded(docInput.type)" class="mt-2 mb-0">
            Uploaded: {{ getFileNameByDocType(docInput.type) }}
          </label>
        </b-form-group>
      </b-col>
    </b-row>

    <slot name="footer"></slot>
  </form>
</template>

<script>
  export default {
    name: 'RequiredDocsUpload',

    props: {
      docs: {
        type: Object,
        required: true
      },
      saved: {
        type: Array,
        required: false,
        default: () => []
      }
    },

    data () {
      return {
        docsCopy: { ...this.docs },
        docInputs: [
          {
            label: 'DTI/SEC',
            type: 'dtisec'
          },
          {
            label: 'Lease Contract',
            type: 'lease'
          },
          {
            label: 'Brgy. Clearance for Business',
            type: 'clearance'
          },
          {
            label: 'Community Tax Certificate',
            type: 'taxcert'
          }
        ]
      }
    },

    watch: {
      docs: {
        deep: true,
        handler: 'copyDocs'
      }
    },

    methods: {
      copyDocs (newDocs) {
        this.docsCopy = { ...newDocs }
      },

      requestSubmit () {
        this.$el.requestSubmit()
      },

      submit () {
        this.$el.submit()
      },

      checkValidity () {
        return this.$el.checkValidity()
      },

      reportValidity () {
        return this.$el.reportValidity()
      },

      handleSubmit () {
        this.$emit('submitting', this.docsCopy)
      },

      getFileNameByDocType (type) {
        const theDoc = this.saved.find(doc => doc.type === type)
        return theDoc?.filename
      },

      isUploaded (type) {
        const theDoc = this.saved.find(doc => doc.type === type)
        return !!theDoc
      }
    }
  }
</script>

<style>
</style>
